import React from 'react';
import {Link} from 'react-router-dom';
import './Footer.css';

export default function Footer1() {
  return (
    <div className='footetr bg-dark'>
   <footer class="container py-4">
  <div class="row">
    <div class="col-12 col-md">
      {/* <small class="d-block mb-3 text-body-secondary">© 2017–2023</small> */}
    </div>
    <div class="col-6 col-md">
      <h5>Features</h5>
      <ul class="list-unstyled text-small">
        <li><Link class="link-secondary text-decoration-none" to="/">Home</Link></li>
        <li><Link class="link-secondary text-decoration-none" to="About">About</Link></li>
        {/* <li><Link class="link-secondary text-decoration-none" to="#">Team feature</Link></li> */}
        <li><Link class="link-secondary text-decoration-none" to="Contact">Contact Us</Link></li>
      </ul>
    </div>
    <div class="col-6 col-md">
      <h5>Resources</h5>
      <ul class="list-unstyled text-small">
        <li><Link class="link-secondary text-decoration-none" to="ShareInfo">Share Information</Link></li>
        {/* <li><Link class="link-secondary text-decoration-none" to="#">Resource</Link></li>
        <li><Link class="link-secondary text-decoration-none" to="#">Another resource</Link></li>
        <li><Link class="link-secondary text-decoration-none" to="#">Final resource</Link></li> */}
      </ul>
    </div>
    <div class="col-6 col-md">
      <h5>Resources</h5>
      <ul class="list-unstyled text-small">
        <li><Link class="link-secondary text-decoration-none" to="PersonalInfo">Personal Information</Link></li>
        {/* <li><Link class="link-secondary text-decoration-none" to="Location">Locations</Link></li> */}
        <li><Link class="link-secondary text-decoration-none" to="Privacy">Privacy</Link></li>
        <li><Link class="link-secondary text-decoration-none" to="Cookie">Cookie</Link></li>
      </ul>
    </div>
    <div class="col-6 col-md">
      <h5>About</h5>
      <ul class="list-unstyled text-small">
        {/* <li><Link class="link-secondary text-decoration-none" to="#">Instagram</Link></li>
        <li><Link class="link-secondary text-decoration-none" to="#">Facebook</Link></li>
        <li><Link class="link-secondary text-decoration-none" to="#">LinkedIn </Link></li>
        <li><Link class="link-secondary text-decoration-none" to="#">Twitter</Link></li> */}
      </ul>
    </div>
  </div>
</footer>
  


    </div>
  )
}
