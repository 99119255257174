import React from "react";
import "./PCDA.css";

function PCDA() {
  return (
    <div className="bg-ground">
      <div><h2 className="tit1">The Secret of Provistant Microsis lies within PDCA methodology.</h2>
      <h3 className="tit2">PDCA methodology deals with Continuous loop of Planning, doing, checking & acting.</h3></div>
    <div classNameNameName="cong" >
      <div className="card112">
        <div className="imgbox1">
          <img
            className="img12"
            src="https://cdn.pixabay.com/photo/2016/11/29/09/32/concept-1868728_1280.jpg"
          />
          <h2 className="head1">planning the idea </h2>
        </div>

        <div className="content11">
          <p>
          P stands for The First Step “being constructive with planning the idea of information technology structure.
          </p>
        </div>
      </div>
      <div className="card114">
        <div className="imgbox1">
          <img src="https://cdn.pixabay.com/photo/2015/07/17/22/43/student-849825_1280.jpg" />
          <h2 className="head1">Do The Task </h2>
        </div>

        <div className="content112">
          <p>
          D stands for Second Step “to perform/do the task based on the idea which was implemented earlier
          </p>
        </div>
      </div>
    </div>
     <div classNameNameName="cong1" >
     <div className="card113">
       <div className="imgbox1">
         <img
           className="img12"
           src="https://cdn.pixabay.com/photo/2015/07/17/22/42/startup-849804_1280.jpg"
         />
         <h2 className="head1">Checking</h2>

       </div>

       <div className="content11">
         <p>
         C stands for Third Step “Being Continuous in reviewing & checking the Present as well as historical data & add enhancements
         </p>
       </div>
     </div>
     <div className="card115">
       <div className="imgbox2">
         <img src="https://cdn.pixabay.com/photo/2016/06/03/13/57/digital-marketing-1433427_1280.jpg" />
         <h2 className="head1">To Be Acted  </h2>
       </div>

       <div className="content116">
         <p>
         A stands for Forth Step “to Be Acted upon the Idea of information technology Structure after considering on All Checking & reviewing details in the past.”
         </p>
       </div>
     </div>
   </div>
   </div>
  );
}

export default PCDA;
