// import { click } from '@testing-library/user-event/dist/click';
// import React, { useState } from 'react';
import { Link  } from 'react-router-dom';
// import {Link as LinkScroll} from 'react-scroll';

export default function Header() {


  return (
    <div>
      <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div class="container-fluid">
          <Link to="/" class="navbar-brand">
            Provistant Microsis
          </Link>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarCollapse">
            <ul class="navbar-nav me-auto mb-2 mb-md-0">
              <li class="nav-item">
                <Link to="/" class="nav-link active" aria-current="page">
                  Home
                </Link>
              </li>
              <li class="nav-item">
                <Link to="About" class="nav-link active">
                  About
                </Link>
              </li>
              <li class="nav-item">
                <Link to="Contact" class="nav-link active">
                  Contact
                </Link>
              </li>
            </ul>
            {/* <form class="d-flex" role="search">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
          <button class="btn btn-outline-success" type="submit">Search</button>
        </form> */}
          </div>
        </div>
      </nav>
    </div>
  );
}


