import React from "react";
import { Link } from "react-router-dom";
import "./MoreAbout.css";

function MoreAbout() {
  return (
    <>
      <div classNameName="moreAbout mt-5">
        <div className="row featurette section12 moreAbout">
          <div className="col-md-7 mt-3">
            <h2 className="featurette-heading fw-normal lh-1 mt-5 ">
              Idea Selection …. <span className="text-body-secondary"></span>
            </h2>
            <p className="lead mt-3">
              A good idea must always need to be generated from an agile brain.
              An Idea may be small, tiny or on miniscule level, it doesn’t
              matter what kind of the size or thought, it may show or impact,
              but the first instance of an Idea require to be generated, because
              it is a starting point from where Race will be started , & in
              Provistant , we make it possible…. After generating an Idea, We
              check the possibilities of developing an idea, for which we select
              Right person to search & see through insights of it, so the
              Complications of the idea can be analysed. In Provistant Microsis,
              We manage & take care of the Complicated Computer Codes so that
              Mankind who have minimum understanding off the technology can use
              them precisely. So that our technology will be User-Friendly…
            </p>
          </div>

          <div className="col-md-5 mt-2  mr-5 img45">
            <img
              src="https://cdn.pixabay.com/photo/2023/09/17/10/28/brain-8258274_1280.jpg"
              classNameName="mr-5"
              alt=""
            />
          </div>
        </div>

        <div className="row featurette section12 moreAbout">
          <div className="col-md-7 order-md-2 ">
            <h2 className="featurette-heading">
              All Aspect identification... <span className="text-muted"></span>
            </h2>
            <p className="lead mt-3">
              In Provistant, we analyse the Potential to utilize the idea among
              users, also we check for user’s reach towards Idea benefits.
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img
              src="https://cdn.pixabay.com/photo/2016/03/10/08/49/hand-1248053_1280.jpg"
              alt=""
            />
          </div>
        </div>

        <div className="row featurette section12 moreAbout">
          <div className="col-md-7 mt-3">
            <h2 className="featurette-heading fw-normal lh-1 mt-5 ">
            Feedback collection… <span className="text-body-secondary"></span>
            </h2>
            <p className="lead mt-3">
            Collecting opinions from user about their experiences, so that working on them to add enhancements in the projects makes it more & more perfect.  The practical expertise & that of target users will help us to predict the likelihood of idea success rate. In Provistant, we build a wide range of opinions. We create community through which Feedback collection becomes easier.
            </p>
          </div>

          <div className="col-md-5 mt-2  mr-5 img45">
            <img
              src="https://cdn.pixabay.com/photo/2019/03/18/09/38/feedback-4062738_640.jpg"
              classNameName="mr-5"
              alt=""
            />
          </div>
        </div>

        <div className="row featurette section12 moreAbout">
          <div className="col-md-7 order-md-2 ">
            <h2 className="featurette-heading">
            Feedback Reaction… <span className="text-muted"></span>
            </h2>
            <p className="lead">
            After collecting & reviewing feedbacks, we make necessary changes to our services strategy & Structural plan according to the feedback. We anticipate the needed to reach set targets. We Design & implementation plan with the main objectives in the short term .
            </p>
          </div>
          <div className="col-md-5 order-md-1">
            <img
              src="https://cdn.pixabay.com/photo/2023/05/12/15/02/customer-feedback-7988708_640.png"
              alt=""
            />
          </div>
        </div>

        <div className="row featurette section12 moreAbout">
          <div className="col-md-7 mt-3">
            <h2 className="featurette-heading fw-normal lh-1 mt-5 ">
            A Basic Version of an idea... <span className="text-body-secondary"></span>
            </h2>
            <p className="lead mt-3">
            In Provistant, We Focus on simplifying the details. It’s important to keep development open to change & feedback, & by holding back until something is ‘just right’ …
            </p>
          </div>

          <div className="col-md-5 mt-2  mr-5 img45">
            <img
              src="https://cdn.pixabay.com/photo/2018/03/21/07/16/learning-3245793_1280.jpg"
              classNameName="mr-5"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default MoreAbout;
