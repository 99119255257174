import React from "react";
import './PersonalInfo.css';

function Shareinfo (){
    return (
<>

    <div className="book">
        <div className="book1">
        <h1>Use of your personal information</h1>
            <div className="book2"><p><li>We use information to contact you: We might use the information you provide to contact you for confirmation of an enquiry on our website or for other promotional purposes.</li></p></div>
            <div className="book2"><p><li>We use information to respond to your requests or questions. We might use your information to confirm your registration for an event or contest. </li></p></div>
            <div className="book2"><p><li>We use information to improve our products and services. We might use your information to customize your experience with us. This could include displaying content based upon your preferences.</li></p></div>
            <div className="book2"><p><li>We use information to look at site trends and customer interests. We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third parties.</li></p></div>
            <div className="book2"><p><li>We use information for security purposes. We may use information to protect our company, our customers, or our websites.</li></p></div>
            <div className="book2"><p><li>We use information for marketing purposes. We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party offers or products we think you might find interesting. Or, for example, if you buy services from us we'll enroll you in our newsletter. </li></p></div>
            <div className="book2"><p><li>We use information to send you transactional communications. We might send you emails or SMS about your account or a service purchase. </li></p></div>
            <div className="book2"><p><li>We use information as otherwise permitted by law.</li></p></div>
        </div>
    </div>
</>

    )
        

    }


export default Shareinfo ;