import Herosection from "./Componants/herosection/Herosection";
import Header from "./Componants/Header/Header";
import Footer1 from "./Componants/Footer1/Footer1";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./Componants/About/About";
import Contact from "./Componants/Contactus/Contact";
import Privacy from "./Componants/Privacy/Privacy";
import Cookie from "./Componants/Cookiey/Cookie";
import PersonalInfo from "./Componants/Personalnfo/PersonalInfo";
import ShareInfo from "./Componants/ShareInfo/ShareInfo";
import Error from "./Componants/Error/Error";
import MoreAbout from "./Componants/MoreAbout/MoreAbout";


// import Pareghraph from "./Componants/Paregraph/Pareghraph";
// import Parellax1 from './Componants/Parellax/Parellax1';
// import PCDA from './Componants/PCDA/PCDA';
// import BuildBetter from './Componants/herosection/Herosection';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />

        <Routes>
          <Route Exact path="/" element={<Herosection />}/>
          <Route Exact path="/about" element={<About />}/>
          <Route Exact path="/contact" element={<Contact />}/>
          <Route Exact path="/privacy" element={<Privacy />}/>
          <Route Exact path="/cookie" element={<Cookie />}/>
          <Route Exact path="/personalInfo" element={<PersonalInfo />}/>
          <Route Exact path="/shareInfo" element={<ShareInfo />}/>
          <Route Exact path="/*" element={<Error />}/>
          <Route path="/MoreAbout" element={<MoreAbout/>}/>
        </Routes>

        {/* <Pareghraph/>
<Parellax1></Parellax1>

<PCDA/>
    <BuildBetter /> */}

        <Footer1 />
      </BrowserRouter>
    </div>
  )
}

export default App;
