import React from "react";
import { Parallax } from "react-parallax";
import { Link } from "react-router-dom";
import Img11 from "../img/img-11.jpg";
import './Parellax.css';
import ParellaxContent from "./ParellaxContent";

function Parellax1() {
  return (
    <div>
      <Parallax className="image1" bgImage={Img11} strength={800}>
        <div className="content ">
          <span className="img1_text">
           <ParellaxContent />
          </span>
        </div>
      </Parallax>
    </div>
  );
}

export default Parellax1;
