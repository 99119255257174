import React from "react";
import "./About.css";
import Img7 from '../img/img-9.jpg';
import img1 from '../img/img-8.jpg';
import img2 from '../img/img-10.jpg';

function Aboutus() {
  return (
    <div className="con124">
      <div className="container43">
        <div className="info">
          <h1 className="info-1"> LEARN MORE ABOUT WHAT WE DO</h1>

          <div className="About">
            <img src={img2} alt="" />
            <p>
              <h1>A Determined Vision...</h1>
              Welcome to Provistant Microsis, the advanced Information
              Technology Startup moving towards way to the future. We are a team
              of like-minded and determined individuals, all sharing a vision
              for success. We believe that our sophisticated technology has the
              potential to become an industry sensation. Would you like to find
              out more? Explore our website today.
            </p>
          </div>
          <div className="info12">
            <img src={Img7} alt="" />
            <div className="In1">
              <p>
                <h1>An Incredible Team... </h1>
                With our innovative and insightful technology, we strive to
                enhance our users’ every day experiences. Founded in 2022, our
                incredible team of engineers, programmers, designers and
                developers has worked tirelessly to bring Provistant Microsis to
                the forefront of the industry.
              </p>
            </div>
          </div>
          <div className="botoom">
            <div className="bot1">
              <img src={img1} alt="" />
              <div className="info2">
                <p>
                  <h1> Understanding the Expectations...</h1>
                  We will continue to work relentlessly to become the
                  technological standard, providing big picture insights and
                  solutions for companies of all sizes. Get in touch to learn
                  more. We built our solutions by closely listening to our
                  potential clientele and understanding their expectations with
                  our product. We know how to analyze this information and
                  customize our offering.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
