import React from 'react';

function Error() {
  return (
    <div>
      
      <h1>Error</h1>
      <h1>Error</h1>
      <p>page not found ! </p>
    </div>
  )
}

export default Error;
