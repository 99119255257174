import React from 'react'
import './OurValues.css'


function OurValues() {
  return (
    <div className='con234'>
      <div class="container py-4">
    <header class="pb-3 mb-4 border-bottom">
      <a href="/" class="d-flex align-items-center text-dark text-decoration-none">
      </a>
    </header>

    <div class="p-5 mb-4 bg-light rounded-3">
      <div class="container-fluid py-5">
        <h1 class="display-5 fw-bold">Our Values:</h1>
        <p class="col-md-8 fs-4">Be the trusted councillor to the user. Positive attitude in mind & heart, to be real & ethical in process, & always strive to provide the service to the user on professional mode.</p>
      </div>
    </div>

    <div class="row align-items-md-stretch">
      <div class="col-md-6">
        <div class="h-100 p-5 text-white bg-dark rounded-3">
          <h2>Winning culture:</h2>
          <p>We believe in generating new idea & implementing them into practical modes. We believe in Adapting new changes & continuously seeking for new challenges so that it could fuel our imagination to develop new ideas. With this Challenging Notes, We always believe in Correcting & continuously improving the prototype of idea. When these Ideas which we generated, Creates new wave in society, at that moment, we are stepping towards winning platform.</p>
        
        </div>
      </div>
      <div class="col-md-6">
        <div class="h-100 p-5 bg-light border rounded-3">
          <h2>Mission & vision:</h2>
          <p>Work as a team because team is “Together Everyone Achieves More”. We work for quality & service betterment & enhancement’s .our Vision & mission contains a motto which is given to the dedicated Team, which comprises of web developers, software developer’s, Engineers, & programmers, we fits these Computer codes in the backend & Frontend level of the application in so precise manner that A normal user who haven’t gather the knowledge of Software, could use it Precisely.</p>
        </div>
      </div>
    </div>
  </div>
    </div>
  )
}

export default OurValues;
