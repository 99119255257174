import React from "react";
import "./Privacy.css";

function Privacy() {
  return (
    <div className="con11">
      <div className="container12">
        <div className="info13">
          <div className="info-14">
      
            <h1>Privacy Policy</h1>
          </div>
        </div>
      </div>
      <div className="privacy">
        <h3 className="Privacy-title">
          This Privacy Policy applies to the Provistantmicrosis.com
        </h3>
        <p>
          Provistantmicrosis.com recognizes the importance of maintaining your
          privacy. We value your privacy and appreciate your trust in us. This
          Policy describes how we treat user information we collect on
          https://www.provistantmicrosis.com and other offline sources. This
          Privacy Policy applies to current and former visitors to our website
          and to our online customers. By visiting and/or using our website, you
          agree to this Privacy Policy.
          <br />
          <br />
          Provistantmicrosis.com is a property of Provistant microsis
          Technologies Pvt Ltd, an Indian Company registered under the Companies
          Act, 2013 having its registered address is S 314/A NR NEW BRIDGE
          CHINCHWAD NA PUNE MH 411033 IN.
          <br />
          <br />
          <h3 className="Privacy-title">Information we collect</h3>
          Contact information. We might collect your name, email, mobile number,
          phone number, street, city, state, pin code, country and IP address.
          <br />
          <br />
          <h3 className="Privacy-title">Payment and billing information</h3>
          We might collect your billing name, billing address and payment method
          when you buy a service. We NEVER collect your credit card number or
          credit card expiry date or other details pertaining to your credit
          card on our website. Credit card information will be obtained and
          processed by our online payment partner CC Avenue.
          <br />
          <br />
          <h3 className="Privacy-title">Information you post</h3>
          We collect information you post in a public space on our website or on
          a third-party social media site belonging to Provistantmicrosis.com.
          <br />
          <br />
          <h3>Demographic information</h3>
          We may collect demographic information about you, events you like,
          events you intend to participate in, services you buy, or any other
          information provided by your during the use of our website. We might
          collect this as a part of a survey also.
          <br />
          <br />
          <h3 className="Privacy-title">Other information</h3>
          If you use our website, we may collect information about your IP
          address and the browser you're using. We might look at what site you
          came from, duration of time spent on our website, pages accessed or
          what site you visit when you leave us. We might also collect the type
          of mobile device you are using, or the version of the operating system
          your computer or device is running.
          <br />
          <br />
          <h3 className="Privacy-title">
            We collect information in different ways
          </h3>
          We collect information directly from you. We collect information
          directly from you when you register for an event or buy services. We
          also collect information if you post a comment on our websites or ask
          us a question through phone or email. We collect information from you
          passively. We use tracking tools like Google Analytics, Google
          Webmaster, browser cookies and web beacons for collecting information
          about your usage of our website.
          <br />
          <br />
          We get information about you from third parties. For example, if you
          use an integrated social media feature on our websites. The
          third-party social media site will give us certain information about
          you. This could include your name and email address.
          <br />
          <br />
        </p>
      </div>
    </div>
  );
}

export default Privacy;
