import React from 'react'
import './Slider.css';

function Slider() {
  return (
    <div id="carouselExampleDark" className="carousel carousel-dark slide">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
  </div>
  <div className="carousel-inner">
    <div className="carousel-item active" data-bs-interval="10000">
      <img src="https://cdn.pixabay.com/photo/2019/09/29/22/06/light-bulb-4514505_1280.jpg" className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h2>Triggering innovative Ideas into existence for Future citizen….</h2>
        <p>•	Bringing together people from different backgrounds & perspectives ….
          <br />
•	Fostering creative environment for free thinking & experimentation…..
<br />
•	Staying up-to-date with the latest trends & advancements in technology, science…
<br />
•	Encourage people to challenge traditional thinking, i.e. ; seeing outside the box……
<br />
•	Encouraging people to take risks & learning from their failures ………….
</p>
      </div>
    </div>
    <div className="carousel-item" data-bs-interval="2000">
      <img src="https://cdn.pixabay.com/photo/2023/09/01/16/01/tech-innovation-8227220_1280.jpg" className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h2>Migrate to advanced technology for New thinking……………</h2>
        <p>Advanced technology which can automate tasks, reduce errors, & streamline processes, allowing people to focus on more creative & innovative work……
Advanced technology enables real-time communication & collaboration, making it easier for people to work together & share ideas.
Advanced technologies that will help collect & analyse large amounts of data, providing insights & opportunities for new ideas & innovation.
Adopting advanced technology will help organizations stay competitive by offering new products, services…
Advanced technology will help create personalized experiences for customers, leading to greater satisfaction & loyalty.
</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src="https://cdn.pixabay.com/photo/2018/03/27/21/43/startup-3267505_1280.jpg" className="d-block w-100" alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h2>Shaping an Idea into reality…</h2>
        <p>•	We define the idea & what problem it solves or what need it fulfils…
          <br />
•	We Research to determine if there is a scope for the idea to flourish…
<br />
•	We Refine the idea by considering feedback from others, identifying potential challenges..
<br />
•	We create a plan that outlines the steps needed to bring the idea to fruition, including timelines, resources needed, & potential roadblocks….
<br />
•	We Test the idea by creating a prototype or conducting a pilot program …
<br />
•	We iterate the idea by using the feedback gathered during testing to refine & improve the idea, & continue to iterate until it is ready to be launched or implemented…..
</p>
      </div>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
  )
}

export default Slider