
import React, {useState} from 'react';
import './Herosection.css';
import Typewriter from 'typewriter-effect';
import Pareghraph from '../Paregraph/Pareghraph';
import Parellax1 from '../Parellax/Parellax1';
import PCDA from '../PCDA/PCDA';
import BuildBetter from './BuildBetter/BuildBetter';
import Slider from '../Slider/Slider';
import OurValues from '../OurValues/OurValues';



export default function Herosection() {

  const [state]= useState({
    titleTwo:'We are Provistant ...   ',
    titleThree:" ",
    
  });
  return (
    <>
    <div className='hero-container'>
    <video src='/videos/video-1.mp4' autoPlay loop muted/>
   <h1>
    <div className='title'>{state.title}</div>
    <div className='titleTwo'>{state.titleTwo}</div>
    <div className="titleThree">{state.titleThree}</div>
   </h1>
   <div className="text">
   <Typewriter className= 'typewiter'
       options ={{
          autoStart:true,
          loop:true,
          delay:40,
          strings:[
            "We’re  the Partner in Creating & converting an Idea into Advanced Information Technology Tool. "
          ]
       }}
      />
   </div>

    </div>
    <Pareghraph/>
    <Parellax1></Parellax1>
    <PCDA/>
    <BuildBetter />
    <Slider/>
    <OurValues/>
    </>
  )
}
