import React from "react";
// import { useState } from "react";
import "./Contact.css";
function Contact() {
  // const [from, setfrom] = useState({
  //   username: "",
  //   email: "",
  //   phone: "",
  //   massage: "",
  // });

  // const habdleInput = (event) => {
  //   const name = event.target.name;
  //   const value = event.target.value;

  //   setfrom((prev) => {
  //     return { ...prev, [name]: value };
  //   });
  // };

  // function submit(){
  //   var x = document.getElementsByTagName("form");
  // x[0].submit();
  // }
  return (
    <>
      <div className="con">
        <div className="cont1">
          <div className="cont2">
            <div className="container24">
              <h1>
                Tell us how we can <br></br>help you
              </h1>
              <div className="card">
                <form
                  action="https://formspree.io/f/mjvqygol"
                  method="POST"
                  id="form1"
                >
                  <input
                    type="text"
                    className="from-control"
                    id="name"
                    name="username"
                    placeholder="Name"
                    autoComplete="off"
                    // value={from.username}
                    // onChange={habdleInput}
                    required
                  />
                  <input
                    type="email"
                    className="from-control"
                    id="name"
                    name="email"
                    placeholder="Email"
                    autoComplete="off"
                    // value={from.email}
                    // onChange={habdleInput}
                    required
                  />
                  <input
                    type="phone"
                    className="from-control"
                    id="name"
                    name="phone"
                    placeholder="Phone"
                    autoComplete="off"
                    // value={from.phone}
                    // onChange={habdleInput}
                    required
                  />

                  <textarea
                    name="massage"
                    placeholder="Message"
                    col="30"
                    rows="6"
                    autoComplete="off"
                    required
                  ></textarea>
                  <input type="submit" value="Submit" id="btn" />
                </form>
                <p>{/* <button onClick={submit}>button</button> */}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
