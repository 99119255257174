import React from 'react'
import './Paraghrap.css';

function Pareghraph() {
  return (
    <div class="px-4 py-5 my-0  text-center contt ">
    <h1 class="display-5 fw-bold  mt-5">Your Trusted Technology Partner….</h1>
    <div class="col-lg-6 mx-auto my-3 py-3 " >
      <p class="lead mb-4 largetext ">Provistant Microsis stands as a prominent Information technology firm. Our unwavering dedication lies in providing comprehensive IT Services. With a user-centric approach, we place great emphasis on attentive listening & swift responsiveness to meet & exceed your needs.</p>
    </div>
  </div>
  )
}

export default Pareghraph