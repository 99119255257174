import React from "react";
import "./BuildBetter.css";

function BuildBetter() {
  return (
    <div className="build">
      <div className="build1">
        <h3>
          For building Better Project, Company needs to be Build better, For
          building Better Company, Team needs to be build better, For Building
          Better Team, An Individual needs to be Build better….
        </h3>
        <p className="buliddes">
          {" "}
          In Provistant, We develop what we can improve, Where technology can
          grow & Intelligence can be encourage. Re-inventing the possibilities
          within Technology for Better tomorrow.
        </p>
      </div>
      <div className="build2">
        <img
          className="ig23"
          src="https://cdn.pixabay.com/photo/2017/01/14/10/56/people-1979261_1280.jpg"
          alt=""
        />
      </div>
    </div>
  );
}

export default BuildBetter;
