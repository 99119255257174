import React from "react";
import { Link } from "react-router-dom";
import MoreAbout from "../MoreAbout/MoreAbout";
import './ParellaxContent.css'; 

function ParellaxContent() {
  return (
    <div>
      <div class="p-5 text-center demo rounded-3">
        <h1 class=" custom-heading ">We create…</h1>
        <p class="col-lg-7 mx-auto para fs-5 ">
        Creating New Information technology tool from innovative ideas for betterment of mankind.In Provistant, We develop the technology which can open new possibilities, which in turns a better way of creating & adapting technology for betterment of Life…<Link to="MoreAbout" >find out more about what we do...</Link>
      
        </p>
      </div>
    </div>
  );
}

export default ParellaxContent;
