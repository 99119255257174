import React from "react";
import './ShareInfo.css';

function  ShareInfo (){
    return (
        <>
<div className="term">
    <div className="terms47">
    <h1>Sharing of information </h1>
<div className="terms48">
    <p>
     <li> We will share information with third parties who perform services on our behalf. We share information with vendors who help us manage our online registration process or payment processors or transactional message processors. </li>
      


    </p>
    <div className="info40">
        <p>
           <li> We will share information with our business partners. This includes a third party who provide or sponsor an event, or who operates a venue where we hold events. Our partners use the information we give them as described in their privacy policies.</li> 
        </p>
    </div>
    <div className="info41">
        <p><li>We may share information if we think we have to in order to comply with the law or to protect ourselves. We will share information to respond to a court order. We may also share it if a government agency or investigatory body requests. Or, we might also share information when we are investigating potential fraud.</li> </p>
    </div>

    <div className="info42">
        <p><li>We may share your information for reasons not described in this policy. We will tell you before we do this.</li></p>
    </div>
    <div className="info43">
        <p><li>If you have any questions about this Policy or other privacy concerns, you can also email us at Enquiries@provistantmicrosis.com</li> </p>
    </div>
    <div className="info44">
        <p><li>If you have any questions about this Policy or other privacy concerns, you can also email us at Enquiries@provistantmicrosis.com </li></p>
    </div>

    <div className="info45"><p><li>This Privacy Policy was last updated on 12.08.2023. From time to time we may change our privacy practices. We will notify you of any material changes to this policy as required by law. We will also post an updated copy on our website. Please check our site periodically for updates.</li></p></div>

<div className="info46"><p><li>Jurisdiction
If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India.</li> 
</p></div>
</div>
    </div>
</div>
        </>
    )
}

export default ShareInfo;