import React from 'react'
import './Cookie.css'

function Cookie() {
  return (<>
    <div className='con12'>
       <div className="container123">
       <div className="info-148">
      <h1>Cookie policy </h1>
    </div>
       </div>
    </div>
   <div className="cookie">
    <p>
    This Cookie Policy ("Policy") explains how Provistant Microsis technologies Pvt Ltd  ("Company") uses cookies and similar technologies on our website and mobile application developed for emergency situations, accidents, natural disasters, emergency alerts, police, ambulance, hospital, fire brigade, rescue mission, animal rescue, child and woman safety, environmental awareness, flood rescue, train accident, plane accident, car accident, cruise ship accident, oil tanker accident, building accident, commercial complex accident, emergency alerts etc. ("Application").
    <br /><br />
    <h3 className='cookies-title'>What are Cookies?</h3>
    Cookies are small data files that are placed on your device when you visit a website or use an application. They are widely used to make websites and applications work more efficiently and to provide information to the owners of the website or application.
    <br /><br />
    <h3 className='cookies-title'>How We Use Cookies</h3>
    We use cookies and similar technologies on our website and mobile application for the following purposes:
Essential Cookies: These cookies are necessary for the website or application to function properly. They enable basic functions like page navigation and access to secure areas of the website or application.
<br />
Analytics Cookies: These cookies help us understand how visitors interact with our website or application by collecting information about the pages they visit and the actions they take. <br />
Advertising Cookies: These cookies are used to deliver advertisements that are more relevant to you and your interests. They may be used to deliver targeted advertising or to limit the number of times you see an advertisement.
<br />
Social Media Cookies: These cookies are used to enable social media sharing and integration on our website or application.
Other Technologies: We may also use other technologies, such as web beacons or pixels, to collect information about your use of our website or application.
<br /><br />

<h3 className='cookies-title'>Managing Cookies</h3>
You can manage cookies through your web browser settings. Most web browsers allow you to control cookies through their settings preferences. However, please note that disabling cookies may affect the functionality of our website or application. <br /><br />

<h3 className='cookies-title'>Third-Party Cookies</h3>
We may also use third-party cookies on our website or application. These cookies are placed by third-party service providers who provide services such as analytics or advertising. We do not have control over these cookies and their use is subject to the privacy policies of the third-party service providers.
<br /><br />
<h3 className='cookies-title'>Updates to this Policy</h3>
We may update this Policy from time to time to reflect changes in our use of cookies and similar technologies. We encourage you to review this Policy periodically for any updates. <br /> <br />

<h3 className='cookies-title' >Contact Us</h3>
If you have any questions or concerns about our use of cookies and similar technologies, please contact us at  enquiries@provistantmicrosis.com
    </p>
   </div>
   </>
  )
}

export default Cookie;